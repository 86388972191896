import useSettings from 'app/hooks/useSettings';

const FollowGreenLogo = ({ className }) => {
  // const { settings } = useSettings();
  // const theme = settings.themes[settings.activeTheme];

  return (
    <div className="flex justify-center" >
      <img src="/assets/brand/followgreen-app-logo.svg" width={'80%'} ></img>
      
    </div>
  );
};

export default FollowGreenLogo;
