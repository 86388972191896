import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { FollowGreenTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import './App.css'
import { MunicipalityProvider } from './contexts/MunicipalityContext';
import { ToastProvider} from 'react-toast-notifications';

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store}>
      <SettingsProvider>
        <FollowGreenTheme>
        <ToastProvider placement="bottom-right">
        <MunicipalityProvider>
          <AuthProvider>{content}</AuthProvider>

        </MunicipalityProvider>
        </ToastProvider>
        </FollowGreenTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
