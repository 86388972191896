import { CircularProgress, Icon } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { getAllMerchantRedemptions, getAllMerchantRedemptionsByMunicipality, getAllOffers, getAllOfferStats, getAllOfferTypes, getUserStats } from 'api';
import useMunicipality from 'app/hooks/useMunicipality';
import moment from 'moment';
import React from 'react'
import { useQuery } from 'react-query';

const AdminStats = () => {

    const { municipality } = useMunicipality();


    //Api call query
    const { isLoading, isError, error, data, isFetching, } =
        useQuery(['userStats', municipality],
            () =>
                getUserStats({ municipalityId: municipality.MunicipalityId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,

            })

    const { isLoading: isLoadingOffers, isError: isErrorOffers, error: errorOffers, data: offers, } =
        useQuery(['offers_stats',municipality],
            () =>
                getAllOfferStats({ municipalityId:  municipality.MunicipalityId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,

            })


            const { isLoading: isOfferTypeLoading , isError: isOfferTypeError,  data: offerTypes, } =
            useQuery('offertypes',() => getAllOfferTypes())

            const { isLoading: isLoadingStats, isError: isErrorStats, error : errorStats, data : stats, } =
            useQuery(['stats', municipality],
                () =>
                getAllMerchantRedemptionsByMunicipality({ municipalityId:  municipality.MunicipalityId }),
                {
                    refetchOnWindowFocus: false,
                    refetchIntervalInBackground: false,
    
                })


            const getRedemption = (offerId) =>
            {
            let redemptionsCount = 0;
              for (let index = 0; index < stats.redemptions.length; index++) {
                const element = stats.redemptions[index];
                if(element.OfferId === offerId) {
                    redemptionsCount++;
                }
                
                
              }
        
              return redemptionsCount;
                 
        
            }    
            
            
            const getRedemptionAll = (offerId) =>
            {
             
                for (let index = 0; index < offers.rows.length; index++) {
                    const _offer = offers.rows[index];
                    let redemptionsCount = 0;
                    for (let _index = 0; _index < stats.redemptions.length; _index++) {
                      const element = stats.redemptions[_index];
                      if(element.OfferId === _offer.OfferId) {
                          console.log("AAA")
                          redemptionsCount++;
                      }
                      
                      
                    }
              
                    offers.rows[index].redemptionsCount =redemptionsCount;
                    
                }
                 
        
            } 
     //Data Table Columns     
     const columns = [
        {
            field: 'TitleEl', headerName: 'Ελληνικός Τίτλος', type: 'text', flex: 5,
            renderCell: (params) => {

                return <div class="p-3 ">{params.row.TitleEl}</div>

            },
        },
        {
            field: 'OfferPoints', headerName: 'Πόντοι Προσφοράς', type: 'text', flex: 2.3,
        },
        {
            field: 'redemptionsCount',
            type: 'num',
            headerName: 'Εξαργυρώσεις', flex: 2,
            // renderCell: (params) => {
            //     console.log(Object.values(params.row)[0])
            //     let val = getRedemption(Object.values(params.row)[0])
            //     return <div class="p-3 ">{val}</div>

            // },
            // valueFormatter: (params) => {
            //     console.log(params)

            //     let val = getRedemption(params.value)
            //     return val;
            // }
        },

        // {
        //     field: 'IsFeatured', headerName: 'Προτεινόμενο', type: 'bool', flex: 3,
        //     renderCell: (params) => {
        //         const status = params.row.IsFeatured;
        //         // return <>{status}</>
        //         if (status === 1)
        //             return <div class="w-full mx-5 px-6 text-center text-white  text-base rounded-xl  bg-green-300 ">Ναι</div>


        //         else return <div class="w-full mx-5 px-6 text-center text-white  text-base rounded-xl  bg-red-300 ">Οχι</div>
        //     },
        // },
        {
            field: 'StartDate', headerName: 'Ημερομηνία Εμφάνισης', flex: 4, type: 'date',
            valueFormatter: (params) => {
                if (params.value)
                    return moment(params.value).format("DD/M/YYYY")
            }

        },
        {
            field: 'EndDate', headerName: 'Ημερομηνία Απενεργοποίησης', flex: 4, type: 'date',
            valueFormatter: (params) => {
                if (params.value)
                    return moment(params.value).format("DD/M/YYYY")
                else return '...'
            }
        },

        

    ];
        


    if (isLoading || isLoadingOffers || isOfferTypeLoading || isLoadingStats)
        return <div className='flex justify-center items-center h-full'><CircularProgress></CircularProgress></div>

    if (isError || isErrorOffers || isOfferTypeError || errorStats || error) {

        return <div className='flex p-8 justify-center h-full items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA: {error} </p>
            </div>
        </div>
    }

    if (data.length < 1 || offers.length < 1 || offerTypes.length < 1)
        return <div className='flex p-8 justify-center h-full items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>No Data To Load</p>
            </div>
        </div>

getRedemptionAll();
    //Actual data
    return (
        <div className='p-10'>
            <div className='flex space-x-5 mb-3 flex-col'>
                <div className='pb-5 flex justify-center font-semibold text-xl mt-0.5'>{'Stats'}</div>

                <div className='pl-8'>Στατιστικά Χρηστών:</div>
                <div className='flex flex-row'>
                    <div className='flex'>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Σύνολο Χρηστών"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold h-full text-white bg-blue-300'>
                                    <div>{data.allUsers}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Επιβεβαιωμένοι χρήστες"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.allUsersConfirmed}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Επιβεβαιωμένο Τηλέφωνο"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.allUsersPhoneConfirmed}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-55'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Χρ. Τρέχων Μήνα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.newUsers}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-55'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Χρ. προηγούμενου Μήνα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.newUsersLastMonth}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-55'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Login Τρέχων Μήνα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.lastMonthActiveCount}</div>
                                </div>
                            </div>
                        </div>

                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-55'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Login πρ. Μήνα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.previousMonthActiveCount}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='pl-8'>Εξαργυρώσεις προσφορών:</div>
                <div className='flex flex-row'>
                    <div className='flex'>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Σύνολο Εξαργυρώσεων"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.redemptionsAll}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Εξ. Τρέχων Μήνα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.redemptionsMonth}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Εξ. προηγούμενου Μήνα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.redemptionsPreviusMonth}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='pl-8'>Εξαργυρώσεις κουπονιών :</div>
                <div className='flex flex-row'>
                    <div className='flex'>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Σύνολο Εξαργυρώσεων"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.receiptsAll}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Εξ. Τρέχων Μήνα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.receiptsMonth}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Εξ. προηγούμενου Μήνα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.receiptsPreviusMonth}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='pl-8'>Στατιστικά Προβολών:</div>
                <div className='flex flex-row'>
                    <div className='flex'>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Σύνολο Views - Άρθρα"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.blogTotalViews}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Σύνολο Views - Videos"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.videosTotalViews}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Σύνολο Views - Quiz"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.quizesTotalViews}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>








                <div className='pl-8'>Στατιστικά Ανακύκλωσης:</div>
                <div className='flex flex-row'>
                    <div className='flex'>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Μπλε κάδος"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.binRecycles.blue}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Κίτρινος κάδος"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.binRecycles.yellow}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Καφέ κάδος"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.binRecycles.brown}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>




                <div className='pl-8'>Στατιστικά Qr Scan:</div>
                <div className='flex flex-row'>
                    <div className='flex'>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Σύνολο Scans"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.qrData.totalScans}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Χρήστες Scan"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.qrData.usersScaned}</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>






                <div className='pl-8'>Στατιστικά Εκπαιδευτικού υλικού:</div>
                <div className='flex flex-row'>
                    <div className='flex'>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Σύνολο πόντων"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.pointsEarned.totalPointsEarned}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Πόντοι Video"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.pointsEarned.videoPoints}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Πόντοι Quiz"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.pointsEarned.quizPoints}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  text-gray-500 '>{"Πόντοι Άρθρων"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold  h-full text-white bg-blue-300'>
                                    <div>{data.pointsEarned.blogPoints}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                <div className='pl-8'>Στατιστικά Συστάσεων:</div>
                <div className='flex flex-row'>
                    <div className='flex'>
                        <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Σύνολο Συστάσεων"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.userReferals.referals}</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='flex p-5 '>
                            <div className='bg-white mt-0 mb-6 m-1 shadow-md rounded-2xl overflow-hidden  w-50'>
                                <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                                    <div className='p-2 text-center  font-semibold  h-full  text-gray-500 '>{"Πόντοι Video"}</div>
                                </div>
                                <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                                    <div>{data.pointsEarned.videoPoints}</div>
                                </div>
                            </div>
                        </div> */}

                    </div>

                </div>







            </div>


            <div className='flex space-x-5 mb-3'>
                <div className='pb-5 flex justify-center font-semibold text-xl mt-0.5'>Offer Stats</div>

                

            </div>
            <DataGrid
                disableExtendRowFullWidth={false}
                autoHeight={true}
                getEstimatedRowHeight={() => 50}
                getRowHeight={() => 'auto'}
                rows={offers.rows}
                columns={columns}
                pageSize={50}
                // onPageSizeChange={(newPage) => {
                //     setPageSize(newPage);
                // }}
                // onPageChange={(newPage) => {
                //     setPage(newPage);
                // }}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: ('Καταχωρήσεις ανα σελίδα')
                    },
                    toolbar: {
                        sx: {
                            backgroundColor: '#F9B233',
                            '& .MuiButton-text': {
                                fontSize: '15px !important',
                                color: 'white',
                            },
                        },
                    },
                }}
                // components={{
                //     Toolbar: showToolbar ? GridToolbar : null,
                // }}
                pagination
                rowCount={offers.count}

                getRowId={(row) => row['OfferId']}
                rowsPerPageOptions={[10, 25, 50, 100]}
                // checkboxSelection
                getRowClassName={(params) =>

                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 2,
                    borderRadius: 1,
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#F3F4F6',
                        fontSize: '0.875rem',
                    },
                    '& .MuiDataGrid-cell': {
                        borderRight: `1px solid ${'#CDCDCD'}`,
                        fontWeight: 300,
                        fontSize: '0.875rem',
                    },
                    '& .MuiDataGrid-row.odd': {
                        backgroundColor: '#FAFAFA',
                    },
                    '& .MuiDataGrid-row.even': {
                        backgroundColor: 'white',
                    },
                }}
            />
        </div>
    )
}

export default AdminStats