import { FollowGreenSuspense } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { FollowGreenLayouts } from './index';

const FollowGreenLayout = (props) => {
  const { settings } = useSettings();
  const Layout = FollowGreenLayouts[settings.activeLayout];

  return (
    <FollowGreenSuspense>
      <Layout {...props} />
    </FollowGreenSuspense>
  );
};

export default FollowGreenLayout;
