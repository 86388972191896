import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();


ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <CssBaseline />
          <App />
          <ReactQueryDevtools></ReactQueryDevtools>
        </BrowserRouter>
      </StyledEngineProvider>
    </QueryClientProvider >
  </React.StrictMode>,
  document.getElementById('root')
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in FollowGreenTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
