export const navigations = [
  { name: 'Dashboard', path: '/dashboard/main', icon: 'dashboard' },
  { name: 'Κουπόνια ', path: '/admin/coupons', icon: 'qr_code' },
  // { name: 'Περιοχές', path: '/admin/areas', icon: 'directions' },
  { name: 'Νέα/Ανακοινώσεις', path: '/admin/news', icon: 'article' },
  { name: 'Blog', path: '/admin/blog', icon: 'book' },
  { name: 'Κουιζ', path: '/admin/quiz', icon: 'quiz' },
  { name: 'Videos', path: '/admin/videos', icon: 'play_circle_filled' },
  { name: 'Ψηφοφορίες', path: '/admin/votes', icon: 'how_to_vote' },
  { name: 'Έμποροι', path: '/admin/merchants', icon: 'storefront' },
  { name: 'Σχολεία', path: '/admin/schools', icon: 'school' },
  { name: 'Διαγωνισμοί Σχολείων', path: '/admin/schoolscontests', icon: 'emoji_events' },
  { name: 'Αθλητικοί Σύλλογοι', path: '/admin/sportclubs', icon: 'sports_soccer' },
  { name: 'Διαγωνισμοί Α.Σύλλογων', path: '/admin/sportclubcontests', icon: 'sports_score' },
  { name: 'Συνδρομητές', path: '/admin/citizens', icon: 'person' },
  { name: 'Δήμοι/Περιφέρειες', path: '/admin/municipalities', icon: 'house' },
  { name: 'Δημοτικοί Φορείς', path: '/admin/publicorganizations', icon: 'dashboard' },
  { name: 'Καδοι Ανακύκλωσης', path: '/admin/recyclebuckets', icon: 'delete' },
  // { name: 'Αναφορά απορριμμάτων', path: '/admin/wastecollectionreports', icon: 'auto_delete' },
  { name: 'Χάρτης συνδρομητών', path: '/admin/usersmap', icon: 'map' },
  { name: 'National Coupon', path: '/admin/nationalcoupons', icon: 'receipt' },
  { name: 'Προτεινόμενα Άρθρα', path: '/admin/userposts', icon: 'bookmark_added' },
  { name: 'Αποστολές', path: '/admin/missions', icon: 'dashboard' },
  { name: 'Stats', path: '/admin/stats', icon: 'query_stats' },
  //Unimplementd  
  // { name: 'Αναφορά Στατιστικών', path: '/admin/votes', icon: 'dashboard' },


  
  // { name: 'Dashboard backup', path: '/dashboard/default', icon: 'dashboard' },
  // { label: 'PAGES', type: 'label' },
  // {
  //   name: 'Session/Auth',
  //   icon: 'security',
  //   children: [
  //     { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
  //     { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
  //     { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
  //     { name: 'Error', iconText: '404', path: '/session/404' },
  //   ],
  // },
  // { label: 'Components', type: 'label' },
  // {
  //   name: 'Components',
  //   icon: 'favorite',
  //   badge: { value: '30+', color: 'secondary' },
  //   children: [
  //     { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
  //     { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
  //     { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
  //     { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
  //     { name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
  //     { name: 'Form', path: '/material/form', iconText: 'F' },
  //     { name: 'Icons', path: '/material/icons', iconText: 'I' },
  //     { name: 'Menu', path: '/material/menu', iconText: 'M' },
  //     { name: 'Progress', path: '/material/progress', iconText: 'P' },
  //     { name: 'Radio', path: '/material/radio', iconText: 'R' },
  //     { name: 'Switch', path: '/material/switch', iconText: 'S' },
  //     { name: 'Slider', path: '/material/slider', iconText: 'S' },
  //     { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
  //     { name: 'Table', path: '/material/table', iconText: 'T' },
  //   ],
  // },
  // {
  //   name: 'Charts',
  //   icon: 'trending_up',
  //   children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  // },
  // {
  //   name: 'Documentation',
  //   icon: 'launch',
  //   type: 'extLink',
  //   path: 'http://demos.ui-lib.com/matx-react-doc/',
  // },
];
