import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios.js";
import { FollowGreenLoading } from "app/components";
import { authUser, setToken } from "api";

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
};

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem("x-auth-token", accessToken);
        setToken(accessToken);
    } else {
        localStorage.removeItem("x-auth-token");
        delete axios.defaults.headers.common.Authorization;
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case "INIT": {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            };
        }
        case "LOGIN": {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user,
            };
        }
        case "LOGOUT": {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        }
        case "REGISTER": {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialState,
    method: "JWT",
    login: () => Promise.resolve(),
    logout: () => { },
    // register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const login = async (email, password) => {
        
        const response = await authUser({
            email,
            password,
        }).catch((e) => {
           
            throw e;
        });
        
        const { token } = response.data;
        setSession(token);

        const payload = jwtDecode(token);
        // console.log("PAYLOAD IS:", payload)
        const user = {
            id: payload.user.id,
            role: payload.user.type,
            name: payload.user.name,
            username: payload.user.name,
            email: payload.user.email,
            // avatar: '/assets/images/Logo_peristeri.jpg',
            // avatar: '',
            // age: 25,
        };

        dispatch({
            type: "LOGIN",
            payload: {
                user,
            },
        });
    };

    const register = async (email, username, password) => {
        // const response = await axios.post('/api/auth/register', {
        //     email,
        //     username,
        //     password,
        // })
        // const { accessToken, user } = response.data
        // setSession(accessToken)
        // dispatch({
        //     type: 'REGISTER',
        //     payload: {
        //         user,
        //     },
        // })
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: "LOGOUT" });
    };

    useEffect(() => {
        (async () => {
            try {
                const accessToken = window.localStorage.getItem("x-auth-token");

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);

                    const payload = jwtDecode(accessToken);

                    const user = {
                        id: payload.user.id,
                        role: payload.user.type,
                        name: payload.user.name,
                        username: payload.user.name,
                        email: payload.user.email,
                        //avatar: '/assets/images/Logo_peristeri.jpg',
                        // avatar: '',
                        //age: 25,
                    };
                    // const response = await axios.get('/api/auth/profile')
                    // const { user } = response.data

                    dispatch({
                        type: "INIT",
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } else {
                    dispatch({
                        type: "INIT",
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: "INIT",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        })();
    }, []);

    if (!state.isInitialised) {
        return <FollowGreenLoading />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "JWT",
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
