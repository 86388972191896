import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios.js";
import { FollowGreenLoading } from "app/components";

const initialState = {
    isInitialised: true,
    municipality: {
        NameEl: "ΔΗΜΟΣ ΚΟΜΟΤΗΝΗΣ",
        MunicipalityId: 3,
        CenterLatitude: 41.116261,

        CenterLongitude: 25.406729,
    },
};

const setSession = (municipalityData) => {
    if (municipalityData) {
        localStorage.setItem(
            "selectedMunicipalityAdmin",
            JSON.stringify(municipalityData)
        );
    } else {
        localStorage.removeItem("selectedMunicipalityAdmin");
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case "INIT": {
            const { municipality } = action.payload;

            return {
                ...state,
                isInitialised: true,
                municipality,
            };
        }
        case "CREATE": {
            const { municipality } = action.payload;

            return {
                ...state,
                isInitialised: true,
                municipality,
            };
        }

        default: {
            return { ...state };
        }
    }
};

const MunicipalityContext = createContext({
    ...initialState,
    init: () => { },
});

export const MunicipalityProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const init = async (municipality) => {
        // const response = await axios.post('/api/auth/login', {
        //     email,
        //     password,
        // })
        // const { municipality } = response.data
        console.log(municipality);
        setSession(municipality);

        dispatch({
            type: "INIT",
            payload: {
                municipality: municipality,
            },
        });
    };

    useEffect(() => {
        (async () => {
            try {
                const savedMunicipality = window.localStorage.getItem(
                    "selectedMunicipalityAdmin"
                );

                if (savedMunicipality) {
                    dispatch({
                        type: "INIT",
                        payload: {
                            municipality: JSON.parse(savedMunicipality),
                        },
                    });
                } else {
                    dispatch({
                        type: "INIT",
                        payload: {
                            municipality: {
                                NameEl: "ΔΗΜΟΣ ΚΟΜΟΤΗΝΗΣ",
                                MunicipalityId: 3,
                            },
                        },
                    });
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    if (!state.isInitialised) {
        return <FollowGreenLoading />;
    }

    return (
        <MunicipalityContext.Provider
            value={{
                ...state,
                init,
            }}
        >
            {children}
        </MunicipalityContext.Provider>
    );
};

export default MunicipalityContext;
