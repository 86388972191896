import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";
import AdminStats from "./admin_stats/AdminStats";

//Areas List
const AreasListView = Loadable(
  lazy(() => import("./municipalities/AreasListView"))
);
const AreaCreate = Loadable(lazy(() => import("./municipalities/AreaCreate")));
const AreaEdit = Loadable(lazy(() => import("./municipalities/AreaEdit")));

//Mayor List
const MayorListView = Loadable(
  lazy(() => import("./municipalities/MayorListView"))
);
const MayorCreate = Loadable(
  lazy(() => import("./municipalities/MayorCreate"))
);
const MayorEdit = Loadable(lazy(() => import("./municipalities/MayorEdit")));

//Coupons
const CouponsListView = Loadable(
  lazy(() => import("./coupons/CouponsListView"))
);
const CouponCreate = Loadable(lazy(() => import("./coupons/CouponCreate")));
const CouponEdit = Loadable(lazy(() => import("./coupons/CouponEdit")));

//News
const NewsListView = Loadable(lazy(() => import("./news/NewsListView")));
const NewsCreate = Loadable(lazy(() => import("./news/NewsCreate")));
const NewsEdit = Loadable(lazy(() => import("./news/NewsEdit")));

//blog
const BlogListView = Loadable(lazy(() => import("./blog/BlogListView")));
const BlogCreate = Loadable(lazy(() => import("./blog/BlogCreate")));
const BlogEdit = Loadable(lazy(() => import("./blog/BlogEdit")));

//Quiz
const QuizListView = Loadable(lazy(() => import("./quiz/QuizListView")));
const QuizCreate = Loadable(lazy(() => import("./quiz/QuizCreate")));
const QuizEdit = Loadable(lazy(() => import("./quiz/QuizEdit")));
const QuestionsListVIew = Loadable(
  lazy(() => import("./quiz/QuestionsListView"))
);
const QuestionCreate = Loadable(lazy(() => import("./quiz/QuestionCreate")));
const QuestionEdit = Loadable(lazy(() => import("./quiz/QuestionEdit")));
const AnswersListVIew = Loadable(lazy(() => import("./quiz/AnswersListView")));
const AnswerCreate = Loadable(lazy(() => import("./quiz/AnswerCreate")));
const AnswerEdit = Loadable(lazy(() => import("./quiz/AnswerEdit")));

//Videos
const VideosListView = Loadable(lazy(() => import("./videos/VideosListView")));
const VideoCreate = Loadable(lazy(() => import("./videos/VideoCreate")));
const VideoEdit = Loadable(lazy(() => import("./videos/VideoEdit")));

//Votes
const VotesListView = Loadable(lazy(() => import("./votes/VotesListView")));
const VoteCreate = Loadable(lazy(() => import("./votes/VoteCreate")));
const VoteEdit = Loadable(lazy(() => import("./votes/VoteEdit")));
const VoteOptionsListView = Loadable(
  lazy(() => import("./votes/VoteOptionsListView"))
);
const VoteOptionCreate = Loadable(
  lazy(() => import("./votes/VoteOptionCreate"))
);
const VoteOptionEdit = Loadable(lazy(() => import("./votes/VoteOptionEdit")));

//Merchants
const MerchantsListView = Loadable(
  lazy(() => import("./merchants/MerchantsListView"))
);
const MerchantCreate = Loadable(
  lazy(() => import("./merchants/MerchantCreate"))
);
const MerchantEdit = Loadable(lazy(() => import("./merchants/MerchantEdit")));

//Offers
const OffersListView = Loadable(
  lazy(() => import("./merchants/OffersListView"))
);
const OfferCreate = Loadable(lazy(() => import("./merchants/OfferCreate")));
const OfferEdit = Loadable(lazy(() => import("./merchants/OfferEdit")));

//Schools
const SchoolsListView = Loadable(
  lazy(() => import("./schools/SchoolsListView"))
);
const SchoolCreate = Loadable(lazy(() => import("./schools/SchoolCreate")));
const SchoolEdit = Loadable(lazy(() => import("./schools/SchoolEdit")));

//School Users
const SchoolsUsersListView = Loadable(
  lazy(() => import("./schools/SchoolsUsersListView"))
);
const SchoolUserCreate = Loadable(
  lazy(() => import("./schools/SchoolUserCreate"))
);
const SchoolUserEdit = Loadable(lazy(() => import("./schools/SchoolUserEdit")));

//Schools Contests
const SchoolContestsListView = Loadable(
  lazy(() => import("./schools_contests/SchoolContestsListView"))
);
const SchoolContestCreate = Loadable(
  lazy(() => import("./schools_contests/SchoolContestCreate"))
);
const SchoolContestEdit = Loadable(
  lazy(() => import("./schools_contests/SchoolContestEdit"))
);

//Schools Projects
const SchoolProjectsListView = Loadable(
  lazy(() => import("./schools_contests/SchoolProjectsListView"))
);
const SchoolProjectCreate = Loadable(
  lazy(() => import("./schools_contests/SchoolProjectCreate"))
);
const SchoolProjectEdit = Loadable(
  lazy(() => import("./schools_contests/SchoolProjectEdit"))
);
const SchoolProjectUpdate = Loadable(
  lazy(() => import("./schools_contests/SchoolProjectUpdate"))
);

//Sport Clubs
const SportclubsListView = Loadable(
  lazy(() => import("./sportclubs/SportclubsListView"))
);
const SportclubCreate = Loadable(
  lazy(() => import("./sportclubs/SportclubCreate"))
);
const SportclubEdit = Loadable(
  lazy(() => import("./sportclubs/SportclubEdit"))
);

//Sport Clubs Contests
const SportclubContestsListView = Loadable(
  lazy(() => import("./sportclub_contests/SportclubContestsListView"))
);
const SportclubContestCreate = Loadable(
  lazy(() => import("./sportclub_contests/SportclubContestCreate"))
);
const SportclubContestEdit = Loadable(
  lazy(() => import("./sportclub_contests/SportclubContestEdit"))
);

//Sport Clubs Projects
const SportClubProjectsListView = Loadable(
  lazy(() => import("./sportclub_contests/SportclubProjectsListView"))
);
const SportClubProjectCreate = Loadable(
  lazy(() => import("./sportclub_contests/SportclubProjectCreate"))
);
const SportClubProjectEdit = Loadable(
  lazy(() => import("./sportclub_contests/SportclubProjectEdit"))
);

//Citizens
const CitizensListView = Loadable(
  lazy(() => import("./citizens/CitizensListView"))
);
const CitizenCreate = Loadable(lazy(() => import("./citizens/CitizenCreate")));
const CitizenEdit = Loadable(lazy(() => import("./citizens/CitizenEdit")));

const AddPoints = Loadable(lazy(() => import("./citizens/AddPoints")));

//Municipalities
const MunicipalitiesListView = Loadable(
  lazy(() => import("./municipalities/MunicipalitiesListView"))
);
const MunicipalityCreate = Loadable(
  lazy(() => import("./municipalities/MunicipalityCreate"))
);
const MunicipalityEdit = Loadable(
  lazy(() => import("./municipalities/MunicipalityEdit"))
);

//Public Organizations
const PublicOrganizationsListView = Loadable(
  lazy(() => import("./public_organizations/PublicOrganizationsListView"))
);
const PublicOrganizationCreate = Loadable(
  lazy(() => import("./public_organizations/PublicOrganizationCreate"))
);
const PublicOrganizationEdit = Loadable(
  lazy(() => import("./public_organizations/PublicOrganizationEdit"))
);

//School Users
const PublicOrganizationUsersListView = Loadable(
  lazy(() => import("./public_organizations/PublicOrganizationUsersListView"))
);
const PublicOrganizationUserCreate = Loadable(
  lazy(() => import("./public_organizations/PublicOrganizationUserCreate"))
);
const PublicOrganizationUserEdit = Loadable(
  lazy(() => import("./public_organizations/PublicOrganizationUserEdit"))
);

//Recycle Buckets
const RecyclebucketsListView = Loadable(
  lazy(() => import("./recycle_buckets/RecycleBucketsListView"))
);
const RecyclebucketCreate = Loadable(
  lazy(() => import("./recycle_buckets/RecycleBucketCreate"))
);
const RecyclebucketEdit = Loadable(
  lazy(() => import("./recycle_buckets/RecycleBucketEdit"))
);

//Recycle Buckets
const WasteCollectionReportListView = Loadable(
  lazy(() => import("./waste_collection_report/WasteCollectionReportListView"))
);
const WasteCollectionReportCreate = Loadable(
  lazy(() => import("./waste_collection_report/WasteCollectionReportCreate"))
);
const WasteCollectionReportEdit = Loadable(
  lazy(() => import("./waste_collection_report/WasteCollectionReportEdit"))
);

//Admin maps - HouseHold
const UsersMap = Loadable(lazy(() => import("./admin_maps/UsersMap")));

//Nation Coupons
const NationalCouponsListView = Loadable(
  lazy(() => import("./national_coupons/NationalCouponsListView"))
);
const NationalCouponCreate = Loadable(
  lazy(() => import("./national_coupons/NationalCouponCreate"))
);
const NationalCouponEdit = Loadable(
  lazy(() => import("./national_coupons/NationalCouponEdit"))
);

//User Posts
const UserPostsListView = Loadable(
  lazy(() => import("./userposts/UserPostsListView"))
);
const UserPostCreate = Loadable(
  lazy(() => import("./userposts/UserPostCreate"))
);
const UserPostEdit = Loadable(lazy(() => import("./userposts/UserPostEdit")));

//Missions
const MissionsListView = Loadable(
  lazy(() => import("./missions/MissionsListView"))
);
const MissionCreate = Loadable(lazy(() => import("./missions/MissionCreate")));
const MissionEdit = Loadable(lazy(() => import("./missions/MissionEdit")));

const MunicipalityMissionsListView = Loadable(
  lazy(() => import("./missions/MunicipalityMissionsListView"))
);
const MunicipalityMissionsCreate = Loadable(
  lazy(() => import("./missions/MunicipalityMissionsCreate"))
);
const MunicipalityMissionsEdit = Loadable(
  lazy(() => import("./missions/MunicipalityMissionsEdit.jsx"))
);

const MissionGoalsListView = Loadable(
  lazy(() => import("./missions/MissionGoalsListView"))
);
const MissionGoalEdit = Loadable(
  lazy(() => import("./missions/MissionGoalEdit"))
);
const MissionGoalCreate = Loadable(
  lazy(() => import("./missions/MissionGoalCreate"))
);
// const MunicipalityMissionsCreate = Loadable(lazy(() => import("./missions/MunicipalityMissionsCreate")));
// const MunicipalityMissionsEdit = Loadable(lazy(() => import("./missions/MunicipalityMissionsEdit.jsx")));
////////////////////////////////
// ROUTES
////////////////////////////////
const adminRoutes = [
  //Areas Routes
  {
    path: "/admin/municipalities/areas",
    element: <AreasListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/municipalities/areas/create",
    element: <AreaCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/municipalities/areas/edit",
    element: <AreaEdit />,
    auth: authRoles.admin,
  },

  //Mayor Routes
  {
    path: "/admin/municipalities/mayor",
    element: <MayorListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/municipalities/mayor/create",
    element: <MayorCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/municipalities/mayor/edit",
    element: <MayorEdit />,
    auth: authRoles.admin,
  },

  //Coupons Routes
  {
    path: "/admin/coupons",
    element: <CouponsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/coupons/create",
    element: <CouponCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/coupons/edit",
    element: <CouponEdit />,
    auth: authRoles.admin,
  },

  //News Routes
  { path: "/admin/news", element: <NewsListView />, auth: authRoles.admin },
  {
    path: "/admin/news/create",
    element: <NewsCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/news/edit",
    element: <NewsEdit />,
    auth: authRoles.admin,
  },

  //Blog Routes
  { path: "/admin/blog", element: <BlogListView />, auth: authRoles.admin },
  {
    path: "/admin/blog/create",
    element: <BlogCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/blog/edit",
    element: <BlogEdit />,
    auth: authRoles.admin,
  },

  //Quiz Routes
  { path: "/admin/Quiz", element: <QuizListView />, auth: authRoles.admin },
  {
    path: "/admin/Quiz/create",
    element: <QuizCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/quiz/edit",
    element: <QuizEdit />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/quiz/questions",
    element: <QuestionsListVIew />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/quiz/questions/create",
    element: <QuestionCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/quiz/questions/edit",
    element: <QuestionEdit />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/quiz/questions/answers",
    element: <AnswersListVIew />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/quiz/questions/answers/create",
    element: <AnswerCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/quiz/questions/answers/edit",
    element: <AnswerEdit />,
    auth: authRoles.admin,
  },

  //Vote Routes
  { path: "/admin/votes", element: <VotesListView />, auth: authRoles.admin },
  {
    path: "/admin/votes/create",
    element: <VoteCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/votes/edit",
    element: <VoteEdit />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/votes/voteoptions",
    element: <VoteOptionsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/votes/voteoptions/create",
    element: <VoteOptionCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/votes/voteoptions/edit",
    element: <VoteOptionEdit />,
    auth: authRoles.admin,
  },

  //Videos Routes
  { path: "/admin/videos", element: <VideosListView />, auth: authRoles.admin },
  {
    path: "/admin/videos/create",
    element: <VideoCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/videos/edit",
    element: <VideoEdit />,
    auth: authRoles.admin,
  },

  //Merchant Routes
  {
    path: "/admin/merchants",
    element: <MerchantsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/merchants/create",
    element: <MerchantCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/merchants/edit",
    element: <MerchantEdit />,
    auth: authRoles.admin,
  },

  //Offers Routes
  {
    path: "/admin/merchants/offers",
    element: <OffersListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/merchants/offers/create",
    element: <OfferCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/merchants/offers/edit",
    element: <OfferEdit />,
    auth: authRoles.admin,
  },

  //School Routes
  {
    path: "/admin/schools",
    element: <SchoolsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schools/create",
    element: <SchoolCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schools/edit",
    element: <SchoolEdit />,
    auth: authRoles.admin,
  },

  //School Users Routes
  {
    path: "/admin/schools/users",
    element: <SchoolsUsersListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schools/users/create",
    element: <SchoolUserCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schools/users/edit",
    element: <SchoolUserEdit />,
    auth: authRoles.admin,
  },

  //School Contests Routes
  {
    path: "/admin/schoolscontests",
    element: <SchoolContestsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schoolscontests/create",
    element: <SchoolContestCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schoolscontests/edit",
    element: <SchoolContestEdit />,
    auth: authRoles.admin,
  },

  //School Projects Routes
  {
    path: "/admin/schoolscontests/schoolprojects",
    element: <SchoolProjectsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schoolscontests/schoolprojects/create",
    element: <SchoolProjectCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schoolscontests/schoolprojects/edit",
    element: <SchoolProjectEdit />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/schoolscontests/schoolprojects/update",
    element: <SchoolProjectUpdate />,
    auth: authRoles.admin,
  },

  //Sport Club Routes
  {
    path: "/admin/sportclubs",
    element: <SportclubsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/sportclubs/create",
    element: <SportclubCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/sportclubs/edit",
    element: <SportclubEdit />,
    auth: authRoles.admin,
  },

  //Sport Club Contests Routes
  {
    path: "/admin/sportclubcontests",
    element: <SportclubContestsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/sportclubcontests/create",
    element: <SportclubContestCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/sportclubcontests/edit",
    element: <SportclubContestEdit />,
    auth: authRoles.admin,
  },

    //Sport Club Projects Routes
    {
      path: "/admin/sportclubcontests/sportclubprojects",
      element: <SportClubProjectsListView />,
      auth: authRoles.admin,
    },
    {
      path: "/admin/sportclubcontests/sportclubprojects/create",
      element: <SportClubProjectCreate />,
      auth: authRoles.admin,
    },
    {
      path: "/admin/sportclubcontests/sportclubprojects/edit",
      element: <SportClubProjectEdit />,
      auth: authRoles.admin,
    },

  //Citizens Routes
  {
    path: "/admin/citizens",
    element: <CitizensListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/citizens/create",
    element: <CitizenCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/citizens/edit",
    element: <CitizenEdit />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/citizens/points",
    element: <AddPoints />,
    auth: authRoles.admin,
  },

  //Municipalities Routes
  {
    path: "/admin/municipalities",
    element: <MunicipalitiesListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/municipalities/create",
    element: <MunicipalityCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/municipalities/edit",
    element: <MunicipalityEdit />,
    auth: authRoles.admin,
  },

  //Public Organizations Routes
  {
    path: "/admin/publicorganizations",
    element: <PublicOrganizationsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/publicorganizations/create",
    element: <PublicOrganizationCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/publicorganizations/edit",
    element: <PublicOrganizationEdit />,
    auth: authRoles.admin,
  },

  //Public Organizations Users Routes
  {
    path: "/admin/publicorganizations/users",
    element: <PublicOrganizationUsersListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/publicorganizations/users/create",
    element: <PublicOrganizationUserCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/publicorganizations/users/edit",
    element: <PublicOrganizationUserEdit />,
    auth: authRoles.admin,
  },

  //Recycle Buckets Routes
  {
    path: "/admin/recyclebuckets",
    element: <RecyclebucketsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/recyclebuckets/create",
    element: <RecyclebucketCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/recyclebuckets/edit",
    element: <RecyclebucketEdit />,
    auth: authRoles.admin,
  },

  //WasteCollectionReport Routes
  {
    path: "/admin/wastecollectionreports",
    element: <WasteCollectionReportListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/wastecollectionreports/create",
    element: <WasteCollectionReportCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/wastecollectionreports/edit",
    element: <WasteCollectionReportEdit />,
    auth: authRoles.admin,
  },

  //Admin maps Routes
  {
    path: "/admin/usersmap",
    element: <UsersMap />,
    auth: authRoles.admin,
  },

  //National Coupons Routes
  {
    path: "/admin/nationalcoupons",
    element: <NationalCouponsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/nationalcoupons/create",
    element: <NationalCouponCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/nationalcoupons/edit",
    element: <NationalCouponEdit />,
    auth: authRoles.admin,
  },

  //User Posts Routes
  {
    path: "/admin/userposts",
    element: <UserPostsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/userposts/create",
    element: <UserPostCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/userposts/edit",
    element: <UserPostEdit />,
    auth: authRoles.admin,
  },

  //Missions Routes
  {
    path: "/admin/missions",
    element: <MissionsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/missions/create",
    element: <MissionCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/missions/edit",
    element: <MissionEdit />,
    auth: authRoles.admin,
  },

  //municipality missions Routes
  {
    path: "/admin/missions/municipalitymission",
    element: <MunicipalityMissionsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/missions/municipalitymission/create",
    element: <MunicipalityMissionsCreate />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/missions/municipalitymission/edit",
    element: <MunicipalityMissionsEdit />,
    auth: authRoles.admin,
  },

  //Missions Goals Routes
  {
    path: "/admin/missions/missiongoal",
    element: <MissionGoalsListView />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/missions/missiongoal/edit",
    element: <MissionGoalEdit />,
    auth: authRoles.admin,
  },
  {
    path: "/admin/missions/missiongoal/create",
    element: <MissionGoalCreate />,
    auth: authRoles.admin,
  },

  //Stats Routes
  {
    path: "/admin/stats",
    element: <AdminStats />,
    auth: authRoles.admin,
  },
];

export default adminRoutes;
